//Typo

$base   : 20px; // base fontsize for the site 
                // flows on into grid spacing for consistency.

//Max width for a non-full width website
$full	: 1080px;

// Key Breakpoints
$sml	: 400px;
$tab    : 720px;
$med	: 1000px;
$lrg	: 1800px; // specifically for wider screens.

$font   : 'Public Sans', Helvetica, sans-serif; // General font used all over the shop
$display: 'Public Sans', Helvetica, sans-serif; // For headings mainly.

//Colours

$main : #595A5C;
$alt: #AAAAAA;
$black : #141414;
$dark-grey : #242424;
$shadow : rgba($black, .25);
$tint : rgba($black, .05);

$success : #00957a;
$warning : #ff8640;
$error   : #CC0000;

// THEMING VARS

$text-colour : $white;
$bg-colour : $black;
$title-colour : $alt;
$card-title-colour : $alt;
$card-bg-colour : $dark-grey;
$btn-colour : $main;
$btn-text-colour : $main;
$btn-hover-colour : darken( $main, 10% );

// Colours used in menus

$menuh : $black; // HOVER
$menua : $main; // ACTIVE

//CUSTOMS

$trans : ease, all, .2s;

.btn { --brand: #{$main}; }
.elevate { --brand: #00aeef; }
.thermalheart { --brand: #8dc63f; }
.vantage { --brand: #FFFFFF; }
.residential { --brand : #337577; }
.designer { --brand : #003063; }
.thermal { --brand : #d6982f; }
.specialty { --brand : #7c2353; }
.architectural { --brand : #028ba3; }
.commercial { --brand : #3b3251; }
.thermal-com { --brand : #669900; }
.framing { --brand : #a12116; }
.hardware { --brand : #afb1b3; }
.safe4kids { --brand : #F7941D; }
.balrated { --brand : #ed1c24; }

// SECTION STYLES

.bg-grey { background-color: #F6F6F6; }
.bg-dark-grey { background-color: $dark-grey; }
.bg-main { 
    .title > h1 { color: #FFF; }
    .btn { background: $white; color: $main; 
        &:hover { background: darken( $white,5% ); }}
    color: #FFF;
    background-color: $main;
}
.bg-half-grey { background: linear-gradient($white, #F6F6F6); }
.bg-black-grey { background: linear-gradient($black, $dark-grey); }


// BASIC UTILS

.flex-col { display: flex; flex-direction: column; }
.flex-row { display: flex; flex-direction: row; }